import React from 'react';
import {Link} from "react-router-dom";
    //
    //  1.29.25 - google analytics interface 
    //
import { useGoogleTag } from '../../hooks/useGoogleTag';

function ServiceItem(props) {
    const { trackEvent } = useGoogleTag();

    const serviceURL = `/service/${props.title.split(' ').join('-').toLowerCase()}?id=${props.id}`
    // Create a handler for service clicks
    const handleServiceClick = () => {
        trackEvent('service_selection', {
            service_id: props.id,
            service_name: props.title,
            service_url: serviceURL,
            click_location: 'services_page'
        });
    };

    return (
        <div className="col-sm-6 col-lg-4">
            <div className="service-item">
                <figure className="service-thumb">
                    <Link 
                        to={`${process.env.PUBLIC_URL + serviceURL}`}
                        onClick={handleServiceClick}
                    >
                        <img 
                            src={require('../../assets/img/' + props.thumb)} 
                            alt={props.title}
                        />
                    </Link>
                    <figcaption className="service-txt">
                        <h5>{props.title}</h5>
                    </figcaption>
                </figure>
                <div className="service-content">
                    <div className="service-content-inner">
                        <h5>
                            <Link 
                                to={`${process.env.PUBLIC_URL + serviceURL}`} 
                                className="stretched-link"
                                onClick={handleServiceClick}
                            >
                                {props.title}
                            </Link>
                        </h5>
                        <p>{props.text}</p>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ServiceItem;