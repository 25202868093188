//  src/hooks/useGoogleTag.js
//  mpa - 1.27.25
//
import { useCallback } from 'react';

export const useGoogleTag = () => {
  const trackEvent = useCallback((eventName, eventParams = {}) => {
    try {
      // Check if dataLayer exists
      if (window.dataLayer) {
        window.dataLayer.push({
          event: eventName,
          ...eventParams
        });
      }
      // Check if gtag exists (for GA4)
      if (window.gtag) {
        window.gtag('event', eventName, eventParams);
      }
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  }, []);

  return { trackEvent };
};

export default useGoogleTag;